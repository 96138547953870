const EMAIL_TEL_REGEXP = /^(\+?[1-9]{1,3}[0-9]{3,14}|^([a-zA-Z0-9]+[_\.-]?)+@(([a-z0-9]+[_-]?)+\.)+([a-z])+)$/g;

const EMAIL_REGEXP = /^([a-zA-Z0-9]+[_\.-]?)+@(([a-z0-9]+[_-]?)+\.)+([a-z])+/;

const TEL_REGEXP = /^\+?[1-9]{1,3}[0-9]{3,14}$/;


export {
  EMAIL_TEL_REGEXP,
  EMAIL_REGEXP,
  TEL_REGEXP,
};
