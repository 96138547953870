// --------------------------------
//      EVERY PAGE SHARED CODE
// --------------------------------

import smoothscroll from 'smoothscroll-polyfill';

import { $all, $q } from './core/utils';
import { initTopBars } from './components/top-bar/top-bar';
import { remFix } from './core/rem-fix';

import './assets/owl.carousel.min.js';
import {EMAIL_REGEXP, TEL_REGEXP} from "./constants";

remFix();

// jquery
declare var $: Function;

//кнопка выбора языка английский
const topBarButtonEn = $q('.top-bar__button-en');
if (topBarButtonEn) {
    topBarButtonEn.addEventListener('click', () => {
        // @ts-ignore
        ym(83617801,'reachGoal','button_en');
    })
}

//кнопка выбора языка русский
const topBarButtonRu = $q('.top-bar__button-ru');
if (topBarButtonRu) {
    topBarButtonRu.addEventListener('click', () => {
        // @ts-ignore
        ym(83617801,'reachGoal','button_ru');
    })
}

//кнопка выбора главной страницы
const topBarButtonAbout = $q('.top-bar__button-about');
if (topBarButtonAbout) {
    topBarButtonAbout.addEventListener('click', () => {
        // @ts-ignore
        ym(83617801,'reachGoal','button_about');
    })
}

//кнопка выбора главной страницы
const topBarButtonContacts = $q('.top-bar__button-contacts');
if (topBarButtonContacts) {
    topBarButtonContacts.addEventListener('click', () => {
        // @ts-ignore
        ym(83617801,'reachGoal','button_contacts');
    })
}

const owlHowIt = $('.how-work__images-block.owl-carousel');
    owlHowIt.owlCarousel({
        center: true,
        margin: 20,
        items: 1,
        loop: true,
        autoPlay: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
        autoplaySpeed: 1000,
    });

    owlHowIt.trigger('to.owl.carousel',[1, 100])
    owlHowIt.trigger('play.owl.autoplay',[3500])

    const tabLeft = document.querySelector('.how-work__tabs .tab--left');
    if (tabLeft) tabLeft.addEventListener('click', () => {
        owlHowIt.trigger('stop.owl.autoplay')
        owlHowIt.trigger('to.owl.carousel',[1, 1000])
        owlHowIt.trigger('play.owl.autoplay',[3500])
    });

    const tabCenter = document.querySelector('.how-work__tabs .tab--center');
    if (tabCenter) tabCenter.addEventListener('click', () => {
        owlHowIt.trigger('stop.owl.autoplay')
        owlHowIt.trigger('to.owl.carousel',[2, 1000])
        owlHowIt.trigger('play.owl.autoplay',[3500])
    });
    const tabRight = document.querySelector('.how-work__tabs .tab--right');
    if (tabRight) tabRight.addEventListener('click', () => {
        owlHowIt.trigger('stop.owl.autoplay')
        owlHowIt.trigger('to.owl.carousel',[3, 1000])
        owlHowIt.trigger('play.owl.autoplay',[3500])
    });

    owlHowIt.on('changed.owl.carousel', function(event) {
        const p = event.property;
        if (p.name === 'position') {
            const mapPosToTab = {
                1: 'tab--center',
                2: 'tab--right',
                3: 'tab--left',
                4: 'tab--center',
                5: 'tab--right',
                6: 'tab--left',
            }
            document.querySelectorAll('.how-work__tabs .tab').forEach(x => x.classList.remove('--active'));

            const tab = mapPosToTab[p.value];
            console.log(tab, p.value);
            document.querySelector('.how-work__tabs .' + tab).classList.add('--active');
        }
    })

window.onload = () => {
    // init smoothscroll
    smoothscroll.polyfill();

    // setup nav with smoothscroll
    $all<HTMLAnchorElement>('a[href]').forEach(el => {
        if (!el.hash) return;

        el.onclick = evt => {
            evt.preventDefault();

            document.querySelector(el.hash)!.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        };
    });

    initTopBars();

    // ----------------------------------------------------
    //                      CONTACTS
    // ----------------------------------------------------
    const contactUsModal = $q('.contact-us-modal');

    function showContactUsModal(visible: boolean) {
        contactUsModal.classList.toggle('--closed', !visible);
    }

    showContactUsModal(false);

    $q('.contact-us-modal__close-btn').onclick = function() {
        showContactUsModal(false);
    };
    $q('.contact-us-modal__btn-ok').onclick = function() {
        showContactUsModal(false);
    };

    const contactUsSubmitBtn = $q('.contact-us__btn-submit');

    if (contactUsSubmitBtn) {
        contactUsSubmitBtn.onclick = function() {
            // @ts-ignore
            ym(83617801,'reachGoal','button_Contact_us');
            const nameInput = $q('.contact-us__input--name') as HTMLInputElement;
            const contactInput = $q('.contact-us__input--email-phone') as HTMLInputElement;
            const contactFrom = $q('.contact-us__input--from') as HTMLInputElement;

            const formData = new FormData();
            formData.append('name', nameInput.value);
            formData.append('contact', contactInput.value);
            if (contactFrom) formData.append('from', contactFrom.value);
            formData.append('lang', document.querySelector('html').getAttribute('lang') || 'en');

            fetch('/post_contactus.php', {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: 'POST',
                body: formData
            }).then(x => x.text()).then(x => {
                console.log('post contactus ' + x);
                if (x === 'ok') showContactUsModal(true);
            });
        };
    }

    // ----------------------------------------------------
    //                   Redirect (ex REQ DEMO MODAL)
    // ----------------------------------------------------

    const goToLiga = $q('.goToLiga');
    if(goToLiga)
    goToLiga.addEventListener('click', ()=>{
        window.open('https://sportliga.com/register')
    })


    const tryDemoBtn = $q('.features__try-demo-btn');
    if(tryDemoBtn)
    tryDemoBtn.addEventListener('click', ()=>{
        console.log(process.env.BUILD_LANG)
        window.location.href = process.env.BUILD_LANG === 'ru' ? '/ru/demo' : '/demo';
    })

    /*const reqDemoModal = $q('.reqdemo-modal');

    if (reqDemoModal) {
        function showReqDemoModal(visible: boolean) {
            reqDemoModal.classList.toggle('--closed', !visible);
        }

        const tryDemoBtn = $q('.features__try-demo-btn');

        if (tryDemoBtn) {
            tryDemoBtn.onclick = function() {
                showReqDemoModal(true);
            };
        }

        showReqDemoModal(false);

        $q('.reqdemo-modal__close-btn').onclick = function() {
            showReqDemoModal(false);
        };

        $q('.reqdemo-modal__send-req').onclick = function() {
            const nameInput = $q('.reqdemo-modal__input--name') as HTMLInputElement;
            const contactInput = $q('.reqdemo-modal__input--email-phone') as HTMLInputElement;
            const contactFrom = $q('.reqdemo-modal__input--from') as HTMLInputElement;

            const formData = new FormData();
            formData.append('name', nameInput.value);
            formData.append('contact', contactInput.value);
            if (contactFrom) formData.append('from', contactFrom.value);
            formData.append('lang', document.querySelector('html').getAttribute('lang') || 'en');

            fetch('/post_contactus.php', {
                method: 'POST',
                body: formData
            }).then(x => x.text()).then(x => {
                console.log('post req demo ' + x);
                showReqDemoModal(false);
                showContactUsModal(true);
            });
        };
    }*/

    // ----------------------------------------------------
    //                    CONTACTS PAGE
    // ----------------------------------------------------

    const feedbackMail = $q('.feedback__button-mail');
    if (feedbackMail) {
        feedbackMail.addEventListener('click', () => {
            // @ts-ignore
            ym(83617801,'reachGoal','button_email');
        })
    }

    const feedbackTelegram = $q('.feedback__button-telegram');
    if (feedbackTelegram) {
        feedbackTelegram.addEventListener('click', () => {
            // @ts-ignore
            ym(83617801,'reachGoal','button_telegram');
        })
    }

    const feedbackForm = $q('.feedback-form');

    if (feedbackForm) {
        const popup = $q('.feedback__popup');
        const popupToggle = $q('.feedback__popup-button');
        const popupContact = $q('.feedback__popup-text_contact');
        const popupMessage = $q('.feedback__popup-text_message');
        const submitBtn = $q('.feedback-form__submit-btn');
        const emailInput = $q('.feedback-form__email') as HTMLInputElement;
        const phoneInput = $q('.feedback-form__phone') as HTMLInputElement;
        const messageInput = $q('.feedback-form__message') as HTMLInputElement;
        const fromInput = $q('.feedback-form__from') as HTMLInputElement;

        submitBtn.onclick = function() {
            // @ts-ignore
            ym(83617801,'reachGoal','button_send_message');
            if (!TEL_REGEXP.test(phoneInput.value) && !EMAIL_REGEXP.test(emailInput.value)) {
                popupContact.classList.add('popup-vision');
                popup.classList.add('feedback__popup_opened');
            } else if (!messageInput.value) {
                popupMessage.classList.add('popup-vision');
                popup.classList.add('feedback__popup_opened');
            } else {
                const formData = new FormData();
                formData.append('name', emailInput.value);
                formData.append('contact', phoneInput.value);
                formData.append('message', messageInput.value);
                if (fromInput) formData.append('from', fromInput.value);
                formData.append('lang', document.querySelector('html').getAttribute('lang') || 'en');

                fetch('/post_contactus.php', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    method: 'POST',
                    body: formData
                }).then(x => x.text()).then(x => {
                    console.log('post contact page ' + x);
                    if (x === 'ok') showContactUsModal(true);
                });
            }
        };

        const tgLink = $q('.feedback-form__tg-btn');
        tgLink.addEventListener('click', () => {
            // @ts-ignore
            ym(83617801,'reachGoal','button_startchat');
            window.open(tgLink.dataset.src);
        });

        popupToggle.addEventListener('click', () => {
            popup.classList.remove('feedback__popup_opened');
            popupContact.classList.remove('popup-vision');
            popupMessage.classList.remove('popup-vision');
        })

    }

    // ----------------------------------------------------
    //                      HOME PAGE
    // ----------------------------------------------------

    const demoLink = $q('.hero__button-demo-link');

    if(demoLink) {
        demoLink.addEventListener('click', () => {
            // @ts-ignore
            ym(83617801,'reachGoal','button_demo');
            window.open(demoLink.dataset.link);
        })
    }


    const owlProductMobile = $('.our-product .hero__phones--mobile .owl-carousel');
    owlProductMobile.owlCarousel({
        // autoHeight: true,
        // autoWidth: true,
        autoPlay: true,
        items: 1,
        loop: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
    });
    owlProductMobile.trigger('play.owl.autoplay',[3500])

    const owlFearures = $('.features__owl-wrapper.owl-carousel');
    owlFearures.owlCarousel({
        center: true,
        margin: 64,
        items: 3,
        loop: true,
        autoPlay: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
        autoplaySpeed: 1000,
    });

    owlFearures.trigger('to.owl.carousel',[1, 100])
    owlFearures.trigger('play.owl.autoplay',[3500])

    const owlKeyPartn = $('.key-partners__bages-wrapper.owl-carousel');
    owlKeyPartn.owlCarousel({
        autoPlay: true,
        items: 3,
        margin: 10,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
        responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1,
                margin: 10,
                center: true,
                loop: true,
            },
            486 : {
                items: 2,
                margin: 100,
                loop: true,

            },
            // breakpoint from 768 up
            768 : {
                items: 2,
                margin: 150,
                loop: true,
            },
            882 : {
                items: 3,
                margin: 10,
                loop: true,
            },
            1024 : {
                items: 4,
                margin: 10,
            },
            1176 : {
                items: 4,
                margin: 100,
            }
        }
    });
    owlKeyPartn.trigger('play.owl.autoplay',[3500])

    // --------------------------------------------------------

    // ----------------------------------------------------
    //                      PRODUCT PAGE
    // ----------------------------------------------------

    const owlProdHero = $('.hero-product__phones--mobile .owl-carousel');
    owlProdHero.owlCarousel({
        // autoHeight: true,
        // autoWidth: true,
        autoPlay: true,
        items: 1,
        loop: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
    });
    owlProdHero.trigger('play.owl.autoplay',[3500])



    const owlKeyPartnProduct = $('.integration__list.owl-carousel');
    owlKeyPartnProduct.owlCarousel({
        autoPlay: true,
        items: 3,
        margin: 10,
        autoplayTimeout: 3500,
        autoplayHoverPause: true,
        responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1,
                margin: 10,
                center: true,
                loop: true,
            },
            // breakpoint from 768 up
            500 : {
                items: 3,
                margin: 10,
            }
        }
    });
    owlKeyPartnProduct.trigger('play.owl.autoplay',[3500])

    // --------------------------------------------------------
};
