import { isMobileScreenWidth } from "../../core/utils";

export function initTopBars() {
    document.querySelectorAll<HTMLHeadingElement>('.top-bar-mobile').forEach(el => {
        initTopBar(el);
    });
}

export function initTopBar(topBarEl: HTMLHeadingElement) {
    const menuOpenBtn = document.querySelector('.top-bar__menu-btn');
    const menuCloseBtn = topBarEl.querySelector('.top-bar-mobile__close');
    // const menuPanel = topBarEl.querySelector('.top-bar-mobile');

    if (!menuOpenBtn || !menuCloseBtn) {
        // `noNavMenu` flag
        console.warn('failed init mobile menu')
        return;
    }

    let _bodyOverflowY: string|null = null;

    const openMobileMenu = () => {
        topBarEl.classList.remove('top-bar-mobile--closed');
        _bodyOverflowY = document.body.style.overflowY;
        document.body.style.overflowY = 'hidden';
    };

    const closeMobileMenu = () => {
        document.body.style.overflowY = _bodyOverflowY;
        topBarEl.classList.add('top-bar-mobile--closed');
    };

    let lastMobile = isMobileScreenWidth();
    const handleResizeMobileDesktop = () => {
        const isMobileS = isMobileScreenWidth();
        if (isMobileS !== lastMobile && !isMobileS) {
            closeMobileMenu();
        }
        lastMobile = isMobileS;
    };

    const menuOpenClick = () => openMobileMenu();

    menuOpenBtn.addEventListener('click', menuOpenClick);
    menuCloseBtn.addEventListener('click', closeMobileMenu);
    window.addEventListener('resize', handleResizeMobileDesktop);

    const destroy = () => {
        menuOpenBtn.removeEventListener('click', menuOpenClick);
        menuCloseBtn.removeEventListener('click', closeMobileMenu);
        window.removeEventListener('resize', handleResizeMobileDesktop);
    };

    return destroy;
}